import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/models/_core/auth-state';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { AuthService } from '../_core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;
  preferredLanguageCode: any;
  authUser: User;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private authService: AuthService
  ) {
    this.authUser = this.authService.getAuthUser();
  }

  getAll(activeOnly: boolean): Observable<User[]> {
    const url = `${this.env.apiUrl}/users?activeOnly=${activeOnly}&take=2000`;
    return this.http.get(url).pipe(map((data: any) => data));
  }

  getByUserName(userName: string): Observable<User[]> {
    const url = `${this.env.apiUrl}/users?userName=${userName}`;
    return this.http.get(url).pipe(map((data: any) => data));
  }

  getById(userId: string): Observable<User> {
    const url = `${this.env.apiUrl}/users/${userId}`;
    return this.http.get(url).pipe(map((data: any) => data));
  }

  impersonate(userId: string): Observable<User> {
    const url = `${this.env.apiUrl}/users/${userId}/impersonate`;
    return this.http.post(url, {}).pipe(map((data: any) => data));
  }

  create(user: User): Observable<any> {
    const url = `${this.env.apiUrl}/users`;
    const body = this.prepareBody(user);
    return this.http.post(url, body).pipe(
      map((data: any) => data),
    );
  }

  update(user: User): Observable<any> {
    const url = `${this.env.apiUrl}/users/${user.id}`;
    const body = this.prepareBody(user);
    return this.http.patch(url, body).pipe(
      map((data: any) => data),
    );
  }

  prepareBody(user: User): any {
    const body = Object.assign({}, user);
    body.fullName = body.lastName + ', ' + body.firstName;
    delete body.createdAt;
    delete body.createdBy;
    delete body.CreatedByUser;
    delete body.id;
    delete body.updatedAt;
    delete body.updatedBy;
    delete body.UpdatedByUser;
    return body;
  }

}
